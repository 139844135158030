import React from "react";

import {
  Typography
  // Customizable Area Start
  , IconButton,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { podcastImage1, headPhone, playBtn} from "./assets";
import Slider from 'react-slick';
import AudioListWeb from "./AudioList.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import Footer from "../../../blocks/navigationmenu/src/Footer.web"; 
import ImageCard from "./components/ImageCard.web";
import AudioCard from "./components/AudioCard.web";
import AudioPlayerComponent from "./AudioPlayerComponent.web";
import EpisodePlayerCard from "./components/EpisodePlayerCard.web";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
interface PlaylistCardProps extends BoxProps {
  bgColor?: string; // Optional bgColor prop
}
const MIN_WINDOW_HEIGHT = 900

const CustomTypography = styled(Box)(({ theme }) => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  marginBottom: "24px",
  fontFamily: "Nexa-Heavy",
}));

const PlaylistCard = styled(Box)<PlaylistCardProps>(({ theme,bgColor  }) => ({
  minWidth: '98px',
  height: '60px',
  padding: '16px 12px 16px 9px',
  borderRadius: '6px',
  backgroundColor: bgColor ||"red",
  color: bgColor ==="white"? "black" : "white",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
}));

const PlaylistContainer = styled(Box)<PlaylistCardProps>(({theme, bgColor }) => ({
  minWidth: "410px",
  height: "92px",
  borderRadius:"6px",
  background:"#44403C",
  // padding:"16px 12px 16px 20px",
  borderLeft:`4px solid ${bgColor}`,
  color:"white",
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center"
}))

const webStyles = {
  container: {
    padding:"20px 50px",
    maxHeight: "100%",
    overflowX: "clip",
    overflowY: "auto",
    maxWidth: "100%",
  } as const,
  }
  const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 2px;
  }
  .slick-dots li {
    margin: 0px !important;
  }
  .slick-dots li button:before {
    color: white; 
    opacity: 1;   
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    color: white !important; 
    font-size: 14px; 
  }
`;
// Customizable Area End

import AudioLibraryController, {
  Props
} from "./AudioLibraryController";

export default class AudioLibrary extends AudioLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedia = () => { return <AudioListWeb totalCount={this.state.totalCount} songs={this.state.audioList} pageNo={this.state.currentPageNo} handlePagination={this.handlePagination}  /> }

  renderAudioLibrarySliderDots(onSliderDotClick: any, followingPodcastCurrentPage: number, allData: any, followingPodcastitemsPerPage: number) {
    const totalPages = Math.ceil(allData?.length / followingPodcastitemsPerPage);
    return (
      <Box display={totalPages <= 1 ? "none" : "flex"} justifyContent="center" alignItems="center" marginTop={2} >
        {Array.from({ length: totalPages }).map((_, index) => (
          <Box
            key={index}
            onClick={() => onSliderDotClick(index)}
            sx={{
              width: 10,
              height: 10,
              backgroundColor: followingPodcastCurrentPage === index ? "#c0c0c0" : "#ffffff",
              borderRadius: "50%",
              marginX: 0.5,
              cursor: "pointer",
              scale: followingPodcastCurrentPage === index ? 1.2 : 0.8,
            }}
          />
        ))}
      </Box>
    );
  }
  
  // Customizable Area End

  render() {
    const savedPlaylist = this.state.continueListen.slice(3, 6);
    const mainPlaylist  = this.state.continueListen.slice(0, 3);
    return (
      // Customizable Area Start
      <>
          <div style={webStyles.container} ref={this.scrollRef}>
          <Box mb={3}> 
          <CustomTypography>
            Continue Listen
          </CustomTypography>
            {/* Main playlist Icon section  */}
            <Box display={"flex"} gap={2} mb={1}>
              <PlaylistCard bgColor={"red"}>
                <img
                  data-test-id="playlist"
                  src={playBtn} 
                  alt="playlist icon"
                  style={{ cursor: "pointer" }}
                />
                <Typography sx={{ fontWeight: 900, fontSize: "8px", lineHeight: "24px" }}>
                  Main playList
                </Typography>
              </PlaylistCard>
              { mainPlaylist.length > 0 ? 
              mainPlaylist.map((playlist, index) => (
                <PlaylistContainer bgColor={"red"} data-test-id={"main_playlist"} onClick = {() => this.props.handleAddAudio("BxBlockLogin::Episode", playlist.episode_id)} key={index}>
                  <Box padding={"16px 12px 16px 20px"}>
                    <Typography sx={{ fontWeight: 900, fontSize: "1rem", lineHeight: "24px", fontFamily: 'Nexa-Heavy' }}>
                      {playlist.episode_name}
                    </Typography>
                    <Typography sx={{ fontWeight: 100, fontSize: "0.75rem", lineHeight: "22px", fontFamily: 'Nexa-Regular', color: "white" }}>
                      {playlist.podcast_name}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      data-test-id="playlist"
                      src={playlist.cover}
                      alt={`${playlist.episode_name} cover Image`}
                      style={{ cursor: "pointer", width: "112px", height: "92px", objectFit:"cover" }}
                    />
                  </Box>
                </PlaylistContainer>
              )) :
              <PlaylistContainer bgColor={"red"}>
                <Box padding={"16px 12px 20px"}>
                  No Episodes available to display
                </Box>
              </PlaylistContainer>
              }
            </Box>
              {/* Saved Episodes Icon section  */}
            <Box display={"flex"} gap={2} mb={1}>
              <PlaylistCard bgColor={"white"}>
                <img
                  src={headPhone} 
                  data-test-id="playlist"
                  style={{ cursor: "pointer" }}
                  alt="playlist icon"
                />
                <Typography sx={{  lineHeight: "24px", fontWeight: 900, fontSize: "8px" }}>
                  Saved Episode
                </Typography>
              </PlaylistCard>
              { savedPlaylist.length > 0 ?
              savedPlaylist.map((playlist, index) => (
                <PlaylistContainer bgColor={"white"} data-test-id={"saved_playlist"} onClick = {() => this.props.handleAddAudio("BxBlockLogin::Episode", playlist.episode_id)} key={index}>
                  <Box padding={"16px 12px 16px 20px"}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: 900, lineHeight: "24px", fontFamily: 'Nexa-Heavy' }}>
                      {playlist.episode_name}
                    </Typography>
                    <Typography sx={{ lineHeight: "22px", fontWeight: 100, fontSize: "0.75rem", color: "white", fontFamily: 'Nexa-Regular'}}>
                      {playlist.podcast_name}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      data-test-id="playlist"
                      src={playlist.cover}
                      alt={`${playlist.episode_name} cover Image`}
                      style={{ cursor: "pointer", width: "112px", height: "92px", objectFit:"cover" }}
                    />
                  </Box>
                </PlaylistContainer>
              )) :
              <PlaylistContainer bgColor={"white"}>
                <Box padding={"16px 12px 16px 20px"}>
                  No Episodes available to display.
                </Box>
              </PlaylistContainer>
            }
            </Box> 

          <CustomTypography sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handlePodCastFollowing}>
            Podcasts I'm Following
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>

          <Box sx={{ display: "flex", alignItems: "center", gap: "24px", flexDirection: "row" }}>
              <Box >
                <IconButton
                  sx={{
                    color: "#FFFFFF",
                    width: "48px",
                    height: "48px",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                  }}
                  disabled={this.state.followingPodcastCurrentPage === 0}
                  onClick={this.handleSliderPreviousPodcastCardClick}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Box>
              {
              this.getFollowingPodCastData().map((data, index) => (
                <ImageCard
                key={index} 
                id={data.id} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                following={data.following}
                onUnfollow={() => this.handleUnFollowPodcast(data.id)}
                onNavigate={() => this.props.handlePodcastId(data.podcastId as string)}
              />
              ))
            }
              <Box >
                <IconButton
                  sx={{
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                    height: "48px",
                    width: "48px",
                  }}
                  onClick={this.handleSliderNextPodcastCardClick}
                  disabled={this.totalPodcastPages()}
                  data-test-id ={"followingPodcasts"}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>

            <Box>
              {this.renderAudioLibrarySliderDots(this.handleDotClick, this.state.followingPodcastCurrentPage, this.state.followingPodCasts, this.followingPodcastitemsPerPage)}
            </Box>
          </Box>
          <CustomTypography data-test-id ={"saved_episode"} sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handleSavedEpisode}>
            Saved Episodes
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>

          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "24px" }}>
              <Box >
                <IconButton
                  sx={{
                    width: "48px",
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                    height: "48px",
                  }}
                  disabled={this.state.savedPodcastCurrentPage === 0}
                  data-test-id ={"savePodcast"}
                  onClick={this.handleSliderPreviousSavedPostClick}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Box>
            {
              this.getSavedEpisodeData().map((data, index) => (
                <AudioCard 
                key={index} 
                data-test-id="savedEpisodes"
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                onRemoveSaveLater={() => this.handleRemoveSaveLater(data.saveable_id)}
                onAddToPlayList={() => this.handleAddToPlayList(data.episodeId, "SavedEpisode")}
                episodeId={data.episodeId}
                addPlayAudio={this.props.handleAddAudio}
                removeFromPlayList={() => this.handleRemoveFromPlayList(data.episodeId, "SavedEpisode")}
                />
              ))
            }
          <Box >
                <IconButton
                  sx={{
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                    width: "48px",
                    height: "48px",
                  }}
                  disabled={this.totalSavedEpisodePages()}
                  data-test-id="handleSliderNextSavedPostClick-click"
                  onClick={this.handleSliderNextSavedPostClick}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>

            <Box>
              {this.renderAudioLibrarySliderDots(this.handleSavedPostDotClick, this.state.savedPodcastCurrentPage, this.state.savedEpisode, this.savedPodcastItemsPerPage)}
            </Box>
          </Box>

          <CustomTypography sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handleChannelLibrary}>
            Channel Library
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"360px"}>

            <StyledSlider {...this.getSlickSettings()}>
            {
              this.state.channelLibrary.map((data, index) => (
              <AudioCard 
              key={index} 
              data-test-id="channelLibrary"
              imageUrl={data.cover}
              songName={data.name}
              artist={data.description}
              selected={data.saved}
              added={data.added}
              onRemoveSaveLater={() => this.handleRemoveFromSaveLaterInChannelLibrary(data.savedId)}
              onAddToPlayList={() => this.handleAddToPlayList(data.episodeId, "ChannelLibrary")}
              episodeId={data.episodeId}
              addPlayAudio={this.props.handleAddAudio}
              removeFromPlayList={() => this.handleRemoveFromPlayList(data.episodeId, "ChannelLibrary")}
              onAddToSave={() => this.handleAddToSaveLaterInChannelLibrary(data.episodeId)}
              />
              ))
            }
          </StyledSlider> 


          </Box>
          </Box>  
          <Footer navigation={this.props.navigation} id={"1"} />
          </div>
          <div style={{
           paddingLeft: "calc(6% + 135px)"
           }}>
        </div> 
      </>
      // Customizable Area End
    );
  }
}
