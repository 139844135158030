import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { podcastImage1, podcastImage2 } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  screenId: string;
  handleCommonPath: (path: string) => void;
  handleAddAudio : (playable_type: string, playable_id: string) => void;
}

interface SavedEpisode {
    imageUrl: string;
    selected: boolean;
    added: boolean;
    songName: string;
    artist: string;
    episodeId: string;
    saved_id: string;
}

interface S {
    savedEpisode: SavedEpisode[];
}

interface SS {
  screenId: string;
}
interface ValidResponse{
  data: [{
    attributes: {
      saveable: {
        data: {
          attributes: {
            save_episode: { saved: boolean, saved_id: string },
            cover: string,
            name: string,
            description: string,
            added: { song_added: boolean }
          }
        }
      }
    }
  }]
}

export default class ChannelLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
    channelLibraryId : string = "";
    savedEpisodeApiCallId : string = "";
    unSaveEpisodeApiCallId: string = "";
    addToMainPlayListAPICallId: string = "";
    removeFromMainPlayListAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        savedEpisode: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId == this.savedEpisodeApiCallId) {
      this.savedEpisodeFetchSuccessCallBack(responseJson)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.savedEpisodeFetch()
  }

  savedEpisodeFetch = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savedEpisodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedEpisode
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  savedEpisodeFetchSuccessCallBack = (responseJson: ValidResponse) => {
    let data = responseJson.data;
    let savedData = data.map((element: any) => {
      let elementAttributes = element.attributes.saveable.data.attributes;
      return ({
        songName: elementAttributes.name,
        selected: elementAttributes.save_episode.saved,
        imageUrl: elementAttributes.cover,
        artist: elementAttributes.description,
        added: elementAttributes.added.song_added,
        episodeId: element.attributes.saveable.data.id,
        saved_id: elementAttributes.save_episode.saved_id
      })
    });
    this.setState({ savedEpisode: savedData });
  }
}