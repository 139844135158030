import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid,TextField , Avatar, Dialog, Modal, ListItemIcon, ListItemText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Star, StarBorder, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ThumbUp, PushPinOutlined,ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, Favorite ,PlaylistAddCheck } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 , messageLogo,imageEyeoff,imageBlock,imageEdit,imageDelete} from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CatalogueController , { Props, PostCommentObject ,configJSON, EpisodeData, HostsData, Post , SubCategoryData } from "./CatalogueController";
import Divider from '@mui/material/Divider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import Rating from '@mui/material/Rating';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPodcastCard = () => {
   const { saveEpisodeListData: { podcast: { data: { attributes } } } } = this.state;
   return (
    <>
    {attributes.episodes.data.length > 0 && attributes.episodes.data.map((episode : EpisodeData, index:number) => (
      <Card style={{backgroundColor: `${this.getSecondaryColor()}`}} className="podcast-card">
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={episode?.attributes?.cover}
        alt="Podcast episode"
        className="podcast-media"
      />
      <Box className="podcast-inner-box">
         <CardContent className="podcast-inner-box-content" >
          <Typography style={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {episode?.attributes?.name}
          </Typography>
          <Typography style={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {attributes?.name}
          </Typography>
          <Typography sx={{ marginTop: '10px', color: `${this.getTextColor()}` }} className="podcast-inner">
           {episode?.attributes?.episode_number}
          </Typography>
          <Typography variant="body2" sx={{ color: `${this.getTextColor()}`, marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
            {episode?.attributes?.description}
            <Link style={{ color: `${this.getTextColor()}`, textDecorationColor: `${this.getTextColor()}` }}>See More</Link>
          </Typography>
        </CardContent>  
        <Box className="podcast-action-buttons">
          {episode?.attributes.liked_episode?.liked ? (
            <IconButton data-test-id = {`likeTestId${index}`} onClick={() => this.handleUnLikeEpisode(episode?.attributes?.liked_episode?.liked_id)} aria-label="like" sx={{ color: 'white' }}>
             <ThumbUp sx={{color: `#FFFFFF`}} /> 
          </IconButton>
          ) : (
            <IconButton data-test-id = {`unlikeTestId${index}`} onClick={() => this.handleLikeEpisode(episode?.id)} aria-label="like" sx={{ color: 'white' }}>
             <ThumbUpOffAlt sx={{color: `${this.getTextColor()}`}} /> 
          </IconButton>
          )}
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
            {episode?.attributes?.liked_episode?.count}
          </Typography>
          <IconButton aria-label="comment" sx={{ color: 'white' }}>
            <ChatBubbleOutline sx={{color: `${this.getTextColor()}`}} />
          </IconButton>
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px' }} className="podcast-inner">
            {episode?.attributes?.comments?.data?.length}
          </Typography>
          <IconButton onClick={this.handleOpenShareChannel} aria-label="share" sx={{ color: '#FFFFFF' }}>
            <Share sx={{color: `${this.getTextColor()}`}} />
          </IconButton>
          <Typography sx={{ color: `${this.getTextColor()}` }} className="podcast-inner">
            {episode?.attributes?.share_episode_count}
          </Typography>
        </Box>
      </Box>
      <Box className="podcast-saving-options">
        {!episode.attributes.save_episode.saved ? (
         <Button data-test-id = {`saveLaterTestId${index}`} style={{background: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`}} onClick={() => this.handleSaveForLater(episode?.id)} variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder/>}>
         <Typography
           style={{
             fontWeight: 900,
             fontSize: '10px',
             lineHeight: '26px',
             whiteSpace: 'nowrap',
             fontFamily: 'Nexa-Heavy',
           }}
         >
           Save For Later
         </Typography>
       </Button>
        ) : (
          <Button style={{backgroundColor: "#030303", borderColor:"#000000"}} data-test-id = {`unsaveLaterTestId${index}`} onClick={() => this.handleUnSaveForLater(episode?.attributes.save_episode.saved_id)} variant="outlined" className="podcast-save-button" startIcon={<Favorite sx={{fill : "#FF0807"}}/>}>
         <Typography
           style={{
             fontWeight: 900,
             fontSize: '10px',
             lineHeight: '26px',
             whiteSpace: 'nowrap',
             fontFamily: 'Nexa-Heavy',
           }}
         >
           Saved
         </Typography>
       </Button>
        )}
        {!episode.attributes.added.song_added ? (
          <Button data-test-id = {`addToPlaylistTestId${index}`}  onClick = {() => this.handleAddtoPlaylist(episode?.id)} variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />} style={{background: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`}}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Add to Playlist
          </Typography>
        </Button>
        ) : (
          <Button data-test-id = {`removeToPlaylistTestId${index}`} onClick = {() => this.handleRemovetoPlaylist(episode?.id)}  variant="outlined" className="podcast-save-button" startIcon={<PlaylistAddCheck />} style={{background: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`}}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Added
          </Typography>
        </Button>
        )}
        
      </Box>
      <Box className="play-icon">
        <PlayCircleFilled data-test-id = {`play-episode-testID${index}`}  style={{ marginBottom: '10px', fontSize: '40px', color: `${this.getButtonColor()}` }} onClick = {() => this.props.handleAddAudio("BxBlockLogin::Episode", episode.id)}  />
      </Box>
      </Card>
    ))}
   
    </>
  );
  } 

  renderRatingStar(index: number) {
    return (
      this.state.ratingStars >= index + 1 ? (
        <Star style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      ) : (
        <StarBorder style={{ color: "#FFFFFF", height:"45px", width:"46px" }} />
      )
    )
  }

  renderSaveUnsaveText(item: any) {
    return (
      item.attributes.save_post.saved_id ? (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}
          data-test-id="unsavePostTestBtn"   
          onClick={() => this.handleUnSavePostDropDown(item.attributes.save_post.saved_id)}
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
          </ListItemIcon>
          <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownUnsave}</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", } }}  
          data-test-id="savePostTestBtn"    
          onClick={() => this.handleSavePostDropDown(item.id)}
        >
          <ListItemIcon>
            <SaveOutlinedIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
          </ListItemIcon>
          <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownSave}</ListItemText>
        </MenuItem>
      )
    )
  }

  renderPinnedIcon(item: any) {
    return (
      item.attributes.pinned && (
        <PushPinOutlined style={{ color: `${this.getTextColor()}`, transform: "rotate(40deg)", width: "24px", height:"24px" }} />
      )
    )
  }

  renderNotificationText(item: any) {
    return (
      item.attributes.post_notification ? (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOffNotification}</ListItemText>
      ) : (
        <ListItemText style={{ fontWeight:400, fontFamily:"Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.TurnOnNotification}</ListItemText>
      )
      
    )
  }

  checkIfPostLiked(item: any) {
    return (
      item.attributes.liked_post.liked_id ? (
        <IconButton aria-label="like" sx={{ color: 'white', marginLeft:"15px" }} data-test-id="likePost" onClick={() => this.handleUnlikePost(item.attributes.liked_post?.liked_id)}>             
          <ThumbUp sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
        </IconButton>
      ) : (
        <IconButton aria-label="like" sx={{ color: 'white', marginLeft:"15px" }} data-test-id="UnlikePost" onClick={() => this.handleLikePost(item.id)}>
          <ThumbUpOffAlt sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
        </IconButton>
      )
    )
  }

  renderChannelInfo = () => {
    const { podcast: { data: { attributes } }} = this.state.saveEpisodeListData
    return(
    <>
      <Card className="podcast-card" style={{backgroundColor: `${this.getSecondaryColor()}`}} >
         <Box className="podcast-inner-box">
           <Box sx={{
               display: "flex",
               gap: "30px",
               padding : "25px",
             }}>
              <img src = {attributes?.cover_image} className="channelInfoImg" />
             <Box className = "channelInfoHeader">
               <Typography variant="h5" fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
                 {attributes.name}
               </Typography>
               {attributes.sub_categories && attributes.sub_categories.map((category : SubCategoryData) => (
                <Typography variant="h6"  fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
                 {category.name}
               </Typography>
               ))}
               <Box sx= {{display : "flex" , alignItems : "center" , gap : "5px"}}>
               <LocationOnIcon fontSize="small"  style = {{ color: this.getTextColor()}}/>
               <Typography variant="h6" fontWeight="bold" sx = {{color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
               {attributes.location}
               </Typography>
               </Box>
             </Box>
           </Box>
           <CardContent style = {{padding : "25px"}}>
             <Typography variant="h6" fontWeight="bold" sx={{paddingBottom: 1, mb: 1, color: this.getTextColor(), fontFamily: "Nexa-Heavy"}}>
               {configJSON.aboutTitle}
             </Typography>
             <Typography variant="body2" sx = {{fontFamily : "Nexa-Regular" , color: this.getTextColor()}} paragraph>
               {attributes.description}
             </Typography>
           </CardContent>
           {attributes.hosts.map((item: HostsData) => (
            <Box sx={{padding: 2,borderRadius: 2}}>
             <Box className = "channelInfoHost">
               <Avatar
                 src= {item.profile_picture}
                 alt="Host Avatar"
                 sx={{ width: 80, height: 80 }}
               />
              <Box>
             <Typography variant="h6" sx={{ mb: 1  , color: this.getTextColor() , fontFamily : "Nexa-Heavy"}}>
               {configJSON.host}
             </Typography>
             <Typography variant="body1" fontWeight="bold" sx = {{fontSize : "18px" , color: this.getTextColor()}} >
               {item.first_name + " " + item.last_name}
             </Typography>
             <Typography variant="body2" sx = {{marginTop : "10px" , fontFamily : "Nexa-Regular" , color: this.getTextColor()}}>
              {item.bio}
             </Typography>
           </Box>
             </Box>
           </Box>
           ))}
         </Box>
       </Card>
       <Box className="otherSectionContainer">
         <Typography variant="h4" fontWeight="bold" sx={{ color: '#fff', fontFamily: "Nexa-Heavy" , mb:1}} >
           {configJSON.otherShows}
         </Typography>
         <Card className="otherSectionCardContainer" style={{ backgroundColor: `${this.getSecondaryColor()}` }}>
           <Box className="internalContainer">
             <Box className="otherSectionCard">
               <img src={podcastImage} className="otherSectionCard-img" />
               <Button className = "follow-btn" variant="contained" color = "error">Follow</Button>
               <Box className="otherSectionCard-textContainer">
                 <Typography className="otherSectionCard-text1">{configJSON.hostNameOther}</Typography>
                 <Typography className="otherSectionCard-text2">{configJSON.channelInfoTitle}</Typography>
               </Box>
             </Box>
           </Box>
         </Card>
       </Box>
      </>
    )
  }

  menuItemsData = [
    {
      activeState: 2,
      trueState: { img: imageEdit, text: "Edit", bgColor: "#FF0807" },
      falseState: { img: imageEyeoff, text: "Hide", bgColor: "#FF0807" },
    },
    {
      activeState: 2,
      trueState: { img: imageDelete, text: "Delete", bgColor: "#44403C" },
      falseState: { img: imageBlock, text: "Block User", bgColor: "#44403C" },
    },
  ];

  
  renderComments = (comments: Array<PostCommentObject>, paddingLeft: number, openPopup: boolean) => {
    return (
      <>
      {
        comments.map((comment: PostCommentObject, index: number) => {
          return(
            <React.Fragment key={index}>
              <Card
                className="podcast-card-comment"
                style={{
                  borderRadius: "0px",
                  backgroundColor: index % 2 === 0 ? "#312F2D" : "#44403C",
                }}
              >
                <Box className="podcast-inner-box">
                  <CardContent
                    className="podcast-inner-box-content"
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #E0E0E0",
                      padding: `22px 33px 22px ${paddingLeft}px`,
                    }}
                    >
                      <Box sx={{display:"flex"}} data-test-id="comment-box">
                      <AvatarImage src={comment.attributes.account_details.profile} alt="Avatar" />
                    <Box>
                      <Box className="community-list">
                        <Typography
                          component="div"
                          variant="h6"
                          style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: 900 }}
                        >
                          {`${comment.attributes.account_details.first_name} ${comment.attributes.account_details.last_name}`}
                        </Typography>
                       
                      </Box>
                      
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 100, fontSize: "10px", color: "#FFFFFF" }}
                      >
                        {moment(comment.attributes.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 100, fontSize: "14px", color: "#FFFFFF" }}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                         {comment.attributes.comment}
                      </Typography>
                      <Box className="community-list-buttons">
                        <IconButton sx={{ color: "#FFFFFF" }}>
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography
                          sx={{ color: "#FFFFFF", marginRight: "15px", fontSize: "14px" }}
                        >
                          {comment.attributes.likes.count}
                        </Typography>
                        <IconButton sx={{ color: "#FFFFFF" }} onClick={()=>{this.showNestedComments(comment.id)}}>
                          <ChatBubbleOutline />   
                            <Typography
                              sx={{ color: "#FFFFFF", marginLeft: "10px", fontSize: "14px" }}
                            >
                              {comment.attributes.reply_count}
                            </Typography>
                        </IconButton>
                      </Box>
                    </Box>
                    </Box>

                    <Button
                      id="basic-button"
                      aria-controls={openPopup ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      data-test-id="comment-box"
                      style={{display:"flex", alignItems:"flex-start", height:"fit-content"}}
                      aria-expanded={openPopup ? 'true' : undefined}
                      onClick={(e)=>this.handleClick(e, comment.id)}
                    >
                      <img
                        src={comment.id}
                        style={{
                          width: "25px",
                          height: "4px",
                        }}
                      />
                      <StyledMenu
                        id="basic-menu"
                        open={openPopup}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {this.menuItemsData.map((item, index) => {
                          const state = this.state.activeCommentId === item.activeState ? item.trueState : item.falseState;
                          return (
                            <StyledMenuItem key={index} style={{background:state.bgColor}}>
                              <img src={state.img} alt={state.text} />
                              <div>{state.text}</div>
                            </StyledMenuItem>
                          );
                        })}
                      </StyledMenu>
                    </Button>
                  </CardContent>
                  {
                    comment.id == this.state.nestedCommentId &&
                    <Box>
                      {this.renderCommentInputBox(comment.id, "nested", paddingLeft)}
                      {this.renderComments(this.state.nestedComments, paddingLeft + 62, this.state.openPopup)}
                    </Box>
                  }
                </Box>
              </Card>
            </React.Fragment>
          )
        })
      }
      </>
    )
  };

  renderCommentInputBox = (id: string, commentType: string, padding?: number) => {
    return (
      <Card className="podcast-card-add-comment" style={{paddingLeft:`${padding}px`,borderRadius: `${ padding ? "0px" : "20px 20px 0px 0px" }` ,}}>
        <Box className="podcast-inner-box">
          <PodcastContentBox className="podcast-inner-box-content">
            <Box sx={{ display: 'flex' }}>
              <AvatarImage src={podcastImage} alt="Avatar" />
              <Box sx={{ flex: 1, position: 'relative' }}>
                <TextFieldContainer style={{borderRadius: `${ padding ? "0px" : "20px 20px 0px 0px" }`}}>
                  <StyledTextField
                    value={this.state.textareaValue}
                    onChange={this.handleTextareaChange}
                    fullWidth
                    multiline
                    placeholder="Leave a comment"
                    rows={4}
                    variant="outlined"
                    InputProps={{
                      readOnly: !!this.state.selectedFile,
                    }}
                  />
                </TextFieldContainer>
                <ReplyButtonBox>
                  <StyledIconButton
                    data-test-id="handleSend2"
                    onClick={() =>  this.handleReply(id, commentType) }
                  >
                    <SendRoundedIcon style={{ fill:`${this.getSecondaryColor()}`, width: '13.5px', height: '12.6px' }} />
                  </StyledIconButton>
                </ReplyButtonBox>
              </Box>
            </Box>
          </PodcastContentBox>
        </Box>
      </Card>
    );
  };

  renderTabs = () => {
    return(
      <Box className="left-body-section-tabs">
      <Box sx={{ display: 'flex', gap: '22px' }}>
        <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Episodes')} sx={{ borderRadius: this.checkBorderRadius1(), height: this.checkHeight1() }}>Episodes</Button>
        <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: this.checkBorderRadius2(), height: this.checkHeight2() }}>Community</Button>
        <Button style={{backgroundColor:this.getSecondaryColor(), borderColor:this.getSecondaryColor(), color:this.getTextColor()}} variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: this.checkBorderRadius3(), height: this.checkHeight3() }}>Channel Info</Button>
      </Box>
    </Box>
    )
  }

  renderCreatePost = () => {
    return (
      <Box className="podcast-card" style={{backgroundColor: `${this.getSecondaryColor()}`}}>
        <PodcastInnerBox className="podcast-inner-box">
          {!this.state.showComments && (
            <PodcastContent className="podcast-inner-box-content">
              <Box sx={{ display: 'flex' }}>
                <Avatar
                  src={this.state.userProfileData.attributes?.profile_picture}
                  alt="Avatar"
                  sx={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    marginRight: '10px',
                  }}
                />
                <Box sx={{ flex: 1, position: 'relative' }}>
                  <TextareaWrapper>
                    <StyledTextFieldSecond
                      value={this.state.textareaValue}
                      onChange={this.handleTextareaChange}
                      fullWidth
                      multiline
                      placeholder="Create A Post"
                      rows={4}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                    {this.state.selectedFile && (
                      <FilePreviewBox>
                        <FileImage
                          src={URL.createObjectURL(this.state.selectedFile)}
                          alt="Uploaded Preview"
                        />
                        <FileRemoveIconButton onClick={this.handleRemoveFile}>
                          <CancelRoundedIcon style={{ color: '#312F2D' }} />
                        </FileRemoveIconButton>
                      </FilePreviewBox>
                    )}
                  </TextareaWrapper>
                  <ActionButtonsBox>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="file-upload"
                      onChange={this.handleFileUpload}
                    />
                    <FileUploadButton
                      style={{ backgroundColor: `${this.getButtonColor()}` }}
                      data-test-id="file-uploadId"
                      onClick={() => document.getElementById('file-upload')?.click()}
                    >
                      <AttachFileIcon style={{ fill: `${this.getSecondaryColor()}`, padding:"5px" }} />
                    </FileUploadButton>
                    <SendButton
                      sx={{ 
                        backgroundColor: this.checkIfDisabled1(),
                        '&:hover': {
                          backgroundColor: this.checkIfDisabled1(),
                        },
                      }}
                      disabled={this.checkIfDisabled1() === '#DADADA'}
                      data-test-id="handleSend"
                      onClick={this.handleSend}
                    >
                      <SendRoundedIcon style={{ fill:`${this.getSecondaryColor()}`, width: '13.5px', height: '12.6px' }} />
                    </SendButton>
                  </ActionButtonsBox>
                </Box>
              </Box>
            </PodcastContent>
          )}
        </PodcastInnerBox>
      </Box>

    )
  }

   renderMenuList = (item: Post) => {
    return (
      <Box>
        {
          this.state.openDropdownForMyOrOthersPost === "My Post" ? (
            <Menu
              anchorEl={this.state.openDropDown}
              open={this.openMenu(item)}
              onClose={this.handleMenuCloseForHostUser}
              data-test-id="closeMenuForHostTestBtn"
              sx={menuStyle}
            >
              <MenuItem
                onClick={this.handleMenuCloseForHostUser}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
              >
                <ListItemIcon>
                  <EditIcon sx={{ fontSize: "36px", color: "#FFFFFF", }} />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}>{configJSON.dropDownEdit}</ListItemText>
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff", }} style={{ margin: "0px" }} />
              {this.renderSaveUnsaveText(item)}
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                onClick={() => this.handleTurnOffNotificationOfMyPost(item.id)}
                data-test-id="notificationTurnOffAndOnOfMyPostTestBtn"
              >
                <ListItemIcon>
                  <NotificationsNoneIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                {this.renderNotificationText(item)}
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                onClick={() => this.handleDeletePost(item.id)}
                data-test-id="deletePostTestBtn"
              >
                <ListItemIcon>
                  <DeleteIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.dropDownDelete}</ListItemText>
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              anchorEl={this.state.openDropDownForOtherPost}
              open={this.openMenuForOthersPost(item)}
              onClose={this.handleMenuCloseForOtherUser}
              data-test-id="closeMenuForOthersPostTestBtn"
              sx={menuStyle}
            >
              {this.renderSaveUnsaveText(item)}
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleTurnOffNotificationOfMyPost(item.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                data-test-id="notificationTurnOffAndOnOfOthersPostTestBtn"
              >
                <ListItemIcon>
                  <NotificationsNoneIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                {this.renderNotificationText(item)}
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff" }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleHidePost(item.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807" } }}
                data-test-id="hidePostTestBtn"
              >
                <ListItemIcon>
                  <VisibilityOffIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <ListItemText style={{ fontWeight: 400, fontFamily: "Nexa-Regular", fontSize: "14px", marginLeft: "8px" }}>{configJSON.hideText}</ListItemText>
              </MenuItem>
              <Divider sx={{ borderColor: "#ffffff", }} style={{ margin: "0px" }} />
              <MenuItem
                onClick={() => this.handleBlockUser(item.attributes.account.id)}
                sx={{ height: "60px", "&:hover": { backgroundColor: "#FF0807", }, }}
                data-test-id="blockUserPostTestBtn"
              >
                <ListItemIcon>
                  <BlockIcon sx={{ fontSize: "36px", color: "#FFFFFF", }} />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Nexa-Regular", }}>{configJSON.blockUserText}</ListItemText>
              </MenuItem>
            </Menu>
          )
        }
      </Box>
    )
  }

  renderCommunitySingleComment = (item: Post, key:number) => {
    return(
      <Card className="podcast-card" style={{ border: this.renderPinnedBorder(item.attributes), backgroundColor: `${this.getSecondaryColor()}`, borderRadius:"20px"}}>
      <Box className="podcast-inner-box">
      <CardContent className="post-podcast-inner-box-content" style = {{display : "flex"}}>
        <Box sx = {{padding : "26px 0px 0px 30px", cursor: "pointer",}}>
          <Avatar
              src={item?.attributes.account.attributes?.profile_picture || ""}
              alt="Avatar"
              style={{
                width: "56px",
                height: "56px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
        </Box>
        <Box style={{width:"100%"}}>
      <Box sx = {{margin : "20px 20px 5px 20px", display:"flex", flexDirection:"column", gap:"6px" }}>
        <Box className = "community-list">
          <Typography style={{ width:"100%", cursor: "pointer", color: `${this.getTextColor()}`, fontWeight:900, fontSize:"14px" }} className="podcast-inner">{item.attributes.name}</Typography>
          {this.renderPinnedIcon(item)}
          <IconButton data-test-id= "isOpenMenuID" size="small" sx={{ color: `${this.getTextColor()}` }}
            aria-label="more options"
            onClick={(event) => this.handleOpenDropDownForHostUser(event, item.id, key)}
          >
            <MoreHorizIcon sx={{ color: `${this.getTextColor()}`, fontSize:"36px" }} />
          </IconButton>
          {this.renderMenuList(item)}
        </Box>
          <Typography style={{ cursor: "pointer", color: `${this.getTextColor()}`, fontWeight:100, fontSize:"10px" }} className="podcast-inner">{moment(item.attributes?.created_at).format('DD/MM/YYYY')}</Typography>
          <Typography sx={{ cursor: "pointer", margin:"0px", color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular', fontSize:"14px", fontWeight:100 }}>{item.attributes?.body}</Typography>
          <Box sx = {{padding : "10px", display: this.renderBlockOrFlex(item)}}>
            <img style={{ width:"100%", borderRadius:"43px", cursor: "pointer", }} src = {this.renderPostImage(item)}  />
          </Box>
       </Box>
      <Box sx = {{marginLeft: "20px", width:"fit-content"}} className = "community-list-buttons" >
         <Typography sx={{ color: `${this.getTextColor()}`, fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{moment(item.attributes?.updated_at).fromNow()}</Typography>                                                       
          {this.checkIfPostLiked(item)}                            
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px', fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.liked_post.count}</Typography>
                <IconButton data-test-id="showCommentsId" aria-label="comment" sx={{ color: 'white' }} onClick={() => this.showComments(item.id, key)}>
                  <ChatBubbleOutline sx={{ color: `${this.getTextColor()}`, height: "24px", width: "24px" }} />
                </IconButton>
          <Typography sx={{ color: `${this.getTextColor()}`, marginRight: '15px', fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.comments.data.length}</Typography>
          <IconButton data-test-id="sharePostDialogTextBtn" aria-label="sharepost" sx={{ color: 'white' }} onClick={() => this.handleOpenSharePostModal(item.id)}>
            <Share sx={{ color: `${this.getTextColor()}`, height:"24px", width:"24px" }} />
          </IconButton>
          <Modal
            open={this.openSharePost(item)}
            onClose={this.handleCloseSharePostModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
          >
            <Box sx={SharePostModalstyle}>
              <Box className="modalBox">
                <IconButton  data-test-id="closeSharePostDialog" onClick={this.handleCloseSharePostModal}>
                  <CloseIcon sx={{color: "#fff", position: "absolute", left: "920px"}} />
                </IconButton>
                <ShareChannelModal>
                  <ShareIcon aria-label="share">
                    <Share />
                  </ShareIcon>
                  <ShareChannelText mb={6} mt={4}>{"Share Post"}</ShareChannelText>
                </ShareChannelModal>
                <ShareChannelText variant="subtitle1" gutterBottom>{"Post URL"}</ShareChannelText>
                <UrlBox>
                  <ChannelTextFeild id="postUrl">{item.attributes.post_url}</ChannelTextFeild>
                  <CopyButton data-test-id="copyPostUrlTestBtn" onClick={() => this.handleCopyPostUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                </UrlBox>
              </Box>
            </Box>
          </Modal>
          <Typography sx={{ color: `${this.getTextColor()}`, fontSize:"14px", fontFamily:"Nexa-Heavy", fontWeight:900 }} className="podcast-inner">{item.attributes.share_post_count}</Typography>
        </Box>
        </Box>
        <Box>
      </Box>
      </CardContent>
    </Box>
  </Card>
    )
  }

  renderSingleComment = (item: Post, key:number) => {
    return(
      <Box>
        {this.renderCommunitySingleComment(item, key)}
        <TextComment>
          Comments
        </TextComment>
        {this.state.nestedCommentId == "" && this.renderCommentInputBox(item.id, "post")}
        {this.renderComments(this.state.postComments, 43, this.state.openPopup)}
      </Box>
    )
  }
  // Customizable Area End
  
  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <div style={webStyles.container}>
          <StyledWrapperSetting style={{backgroundColor: `${this.getBackgroundColor()}`}}>
            <Box className="header-section" style={{backgroundColor: `${this.getSecondaryColor()}`}}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" gap="20px">
                <Box display="flex" flexDirection="row" gap="20px">
                  <img src={this.handleImageRender()} alt="Podcast" className="podacst-image" />
                  <Box className="podcast-header-content">
                    <Typography style={{ color: `${this.getTextColor()}`, fontWeight: 900, width:"380px", fontSize: "40px", fontFamily: "Nexa-Heavy" }}>{this.state.saveEpisodeListData.podcast.data.attributes.name}</Typography>
                    <Typography className="podcast-name" style={{color: `${this.getTextColor()}`}}>{this.state.saveEpisodeListData.podcast.data.attributes.subtitle}</Typography>
                    <Box display="flex" alignItems="center">
                      <Rating 
                        sx={{ 
                          color: this.getTextColor(),
                          '& .MuiSvgIcon-root': {
                            fontSize: '31px', 
                          },
                        }} 
                        emptyIcon={<StarBorder sx={{ color: this.getTextColor() }} fontSize="inherit" />}
                        max={5}
                        value={this.state.saveEpisodeListData?.podcast?.data.attributes?.ratings?.ratings} 
                        precision={0.5} readOnly
                      />
                    </Box>
                    
                    <Box display="flex" gap="8px">
                      <Button style={{ width:"164px", height:"44px", }} variant="outlined" data-test-id="rate-testID" onClick={this.handleOpenRateOrReview} className="left-section-buttons">{configJSON.rateOrReviewText}</Button>
                      <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px 8px 32px 8px',
                        },
                      }}
                      open={this.state.openRateOrReview}
                      onClose={this.handleCloseRateOrReview}
                      >
                      <Box data-test-id="rate-review-testID" onClick={this.handleCloseRateOrReview} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          {configJSON.rateThisPodcast}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          {[...Array(this.state.totalStars)].map((_, index) => (
                            <IconButton key={`empty-${index}`} style={{ padding: 0 }} data-test-id="ratingStarsTestBtn" onClick={() => this.handleProvideStars(index)}>
                              {this.renderRatingStar(index)}
                            </IconButton>
                          ))}
                      </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "24px" , marginTop:"16px"}} component="label">
                          {configJSON.leaveAReview}
                          </Typography>
                          <TextField
                            data-test-id="review"
                            name="review"
                            value={this.state.review}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            sx={textFieldStyles}
                          />
                        </Box>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"flex-end"}>
                      <Button  onClick={this.handleCloseRateOrReview} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "#fff",
                        background: "black",
                        height:"56px",
                        width:'120px',
                        "&:hover": {
                            background: "#000", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          disabled = {!this.state.review || this.state.ratingStars === 0}
                          onClick={() => this.postRateOrReviewData()}
                          data-test-id="rateOrReviewTestBtn"
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "#FFFFFF",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'120px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "#fff",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.publishText}
                        </Button>
                      </Box>
                    </Dialog>
                      <Button style={{width:"164px", height:"44px", }} variant="outlined" data-test-id="share-testID" onClick={this.handleOpenShareChannel} className="left-section-buttons">{configJSON.shareChannelModal}</Button>
                      <Modal
                        open={this.state.open}
                        onClose={this.handleCloseShareChannel}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
                      >
                        <Box sx={ShareChannelModalstyle}>
                          <Box className="modalBox" >
                            <IconButton  data-test-id="share-close-testID" onClick={this.handleCloseShareChannel}>
                              <CloseIconModal />
                            </IconButton>
                            <ShareChannelModal>
                              <ShareIcon aria-label="share">
                                <Share />
                              </ShareIcon>
                              <ShareChannelText variant="h6" mb={6} mt={4}>{configJSON.shareChannelModal}</ShareChannelText>
                            </ShareChannelModal>
                            <ShareChannelText variant="subtitle1" gutterBottom>{configJSON.channelUrlText}</ShareChannelText>
                            <UrlBox>
                              <ChannelTextFeild id="podcastUrl">{this.state.saveEpisodeListData?.podcast?.data?.attributes?.public_url}</ChannelTextFeild>
                              <CopyButton data-test-id="copyChannelUrlTestBtn" onClick={() => this.handleCopyChannelUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton>
                            </UrlBox>
                            <ShareChannelText variant="subtitle1" mt={4} gutterBottom>{configJSON.embedText}</ShareChannelText>
                            <LinkContainer>
                              <EmbededTextFeild id="embedUrl">{this.state.saveEpisodeListData?.podcast?.data?.attributes?.embed_code}</EmbededTextFeild>
                              <CopyButton2 data-test-id="copyEmbedUrlTestBtn" onClick={() => this.handleCopyEmbedUrl()} ><ContentCopy />{configJSON.copyText}</CopyButton2>
                            </LinkContainer >
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Typography style={{color: `${this.getTextColor()}`}} className="total-reviews">{this.state.saveEpisodeListData?.podcast?.data.attributes?.ratings?.reviews} Reviews</Typography>
                    <Box display="flex" gap="10px">
                      {!this.state.saveEpisodeListData?.podcast?.data?.attributes?.follower?.following ?
                       <Button variant="contained" className="left-section-bottom-buttons" data-test-id="followButton" sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": { backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }} onClick={() => this.handleFollowButton(this.state.saveEpisodeListData?.podcast?.data?.id)}>Follow</Button> 
                      :  <Button variant="contained" className="left-section-bottom-buttons" data-test-id="unfollowButton" sx={{ backgroundColor: "#030303", "&:hover": { backgroundColor: "#030303", borderColor: "#030303"} }} onClick={() => this.handleUnFollowButton(this.state.saveEpisodeListData?.podcast?.data?.attributes?.follower?.follow_id)}>Followed</Button>}
                      <Button variant="contained" className="left-section-bottom-buttons" sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`} }}><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton className="podcast-header-info-icon" sx={{ backgroundColor: `${this.getButtonColor()}`, "&:hover": {backgroundColor: `${this.getButtonColor()}` } }}><InfoOutlined /></IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" flexDirection="row" gap="20px">
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`, color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: `${this.getButtonColor()}`, borderColor: `${this.getButtonColor()}`, color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: 'capitalize', fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Button 
                        onClick={this.onSendHandler}
                        variant="outlined" style={{ color: `${this.getTextColor()}`, width: '345px', height: "44px", borderRadius: "8px", border: `1px solid ${this.getTextColor()}`, fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone sx={{color: `${this.getTextColor()}`}} />}>
                        Contact Host
                      </Button>
                    </Box>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px',
                        },
                      }}
                      open={this.state.isContactHost}
                      onClose={this.onCancelHandler}
                      >
                      <Box onClick={this.onCancelHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          Contact Host
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" }} component="label">
                            Name *
                          </Typography>
                          <TextField
                            data-test-id="name"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Title *
                          </Typography>
                          <TextField
                            data-test-id="playlistName"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Description *
                          </Typography>
                          <TextField
                            data-test-id="description"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Phone Number (optional)
                          </Typography>
                          <TextField
                            name="phone_num"
                            data-test-id="phoneNum"
                            value={this.state.phone_num}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                            sx={textFieldStyles}
                          />
                        </Box>
                        <div style={{color : "red", fontFamily : "Nexa-Heavy", marginTop:'8px'}}>{this.state.errorMsg}</div>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"space-between"}>
                      <Button onClick={this.onCancelHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'268px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          onClick={this.saveContactHostData}
                          disabled={this.checkIfDisabled2()}
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "white",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'268px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "white",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.saveText}
                        </Button>
                      </Box>
                    </Dialog>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px 8px 32px 8px',
                        },
                      }}
                      open={this.state.contactSuccess}
                      onClose={this.onCloseHandler}
                      >
                      <Box onClick={this.onCloseHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 113px", textAlign:"center"}}>
                        <img src = {messageLogo} style={{ marginBottom:"10px"}}/>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"10px"}}>
                          Message sent!
                        </Typography>
                        <Typography sx={{...webStyles.titledesc, fontSize:"20px"}}>
                          Your message has been sent to the host. They will get back to you soon!                       
                        </Typography>
                      </Box>
                      <Divider sx={{ marginTop:"24px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="24px 0px 52px 0px" justifyContent={"center"}>
                      <Button onClick={this.onCloseHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'187px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.closeText}
                        </Button>
                      </Box>
                    </Dialog>
                  </Box>
                </Box>
              </Box>
            </Box>
            {this.renderTabs()}
            <Box style={{}} sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px' }}>
              <div className="scrollableWrapper">
                <Box className="left-body-section" sx={{  scrollbarColor: `${this.getButtonColor()} #4D4D4D`, overflowY: 'scroll', maxHeight: '64rem' }}>
                  {this.state.selectedTab == "Episodes" && this.renderPodcastCard()}
                  {this.state.selectedTab == "Community" &&
                    <>
                      {this.renderCreatePost()}
                      {this.state.showSingleComment && this.state.communityListData.map((item: Post, key: number) => (
                        <>
                          {this.renderCommunitySingleComment(item, key)}
                        </>
                      ))}
                      {!this.state.showSingleComment && this.renderSingleComment(this.state.communityItem, 2)}
                    </>
                  }
                  {this.state.selectedTab == "ChannelInfo" && this.renderChannelInfo()
                  }
                </Box>
              </div>
               <Box sx={{ minHeight: '100vh' }}>
                <Grid container direction="column" spacing={2} className="right-body-section">
                  <Typography style={{color: `${this.getTextColor()}`}} className="sponsored-title">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: "Nexa-Heavy" }}>
                            The True
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: "Nexa-Regular" }}>
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Heavy' }}>
                            All Consuming
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular' }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" style={{backgroundColor: `${this.getSecondaryColor()}`, borderLeft: `4px solid ${this.getButtonColor()}`}}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Heavy' }}>
                            This American Life
                          </Typography>
                          <Typography sx={{ color: `${this.getTextColor()}`, fontFamily: 'Nexa-Regular' }}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_3}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="column" gap="25px" >
                  <Typography sx={{ color: `${this.getTextColor()}`, marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    alt="Burger Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                  <Box
                    component="img"
                    src={ad_2}
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
      </>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const buttonStyles = {
  borderRadius: "8px",
  fontFamily: "Nexa-Heavy",
  fontSize: "16px",
  textTransform: "capitalize",
  height: "34px",
};

const textFieldStyles = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: 'white',
    fontFamily: 'Nexa-Heavy',
    fontWeight: 900,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};


const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  padding:"20px",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podcast-name": {
    fontWeight: "900",
    fontSize: "16px",
    fontFamily: "Nexa-Heavy",
    width:"371px",
    textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"
  },
  "& .podacst-image": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-header-content": {
    display:"flex", flexDirection:"column", gap:"10px",
    color: "white",
    fontWeight: "bold",
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
  },
  "& .total-reviews": {
    fontWeight: "900",
    fontSize: "18px",
    fontFamily: "Nexa-Heavy"
  },
  "& .left-section-bottom-buttons": {
    color: "white",
    borderRadius: "8px",
    width: "164px",
    height: '44px',
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
  },
  "& .left-body-section-tabs": {
    // width: "536px",
    height: "52px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .section-tabs": {
    color: 'white',
    textTransform: "capitalize",
    borderColor: "#44403C",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .scrollableWrapper": {
    height: "65rem",
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .left-body-section": {
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    gap: "24px",
    // width: "1005px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-card-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-card-add-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    borderRadius:"20px 20px 0px 0px",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .post-podcast-inner-box-content": {
    flex: '1 0 auto',
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-save-button": {
    color: 'white',
    marginRight: "0px",
    width: "118px",
    height: "26px",
    borderRadius: "8px",
    textTransform: "capitalize",
    gap: "8px",
    "&:first-of-type": {
      marginTop: "25px",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    }
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .sponsored-title": {
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy'
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .recommended-cards": {
    display: 'flex',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  },
  "& .community-list": {
   display : "flex",
   justifyContent : "space-between",
   alignItems: "center",
   height:"32px"
  },
  
  "& .community-list-buttons": {
    display : "flex",
    alignItems: "center"
   },
  "& .otherSectionContainer":{
   marginTop:"50px",
   width:"100%",
  },
  "& .otherSectionCardContainer":{
    display:"flex",
    alignItems: "center",
    justifyContent :"center",
    backgroundColor :"#312F2D",
  },
  "& .internalContainer":{
    display : "flex",
    justifyContent : "space-around",
    flexWrap : "wrap",
    width : "1000px",
  },
"& .otherSectionCard-img":{
    width : "285px", 
    height : "290px",
 
},
  "& .otherSectionCard":{
    width : "286px", 
    height : "375px",
    backgroundColor :"#000",
    marginTop:"40px",
    marginBottom:"30px",
    borderRadius: "0px 20px 20px" ,
    position : "relative"
  },
  "& .otherSectionCard-text1":{
    fontFamily : "Nexa-Heavy",
    fontSize : "18px",
    color : "#fff",
    width : "250px", 
},
"& .otherSectionCard-text2":{
  fontFamily : "Nexa-Heavy",
  fontSize : "16px",
  color : "#fff",
  width : "250px", 
},
"& .otherSectionCard-textContainer":{
  margin: "20px"
}, 
"& .channelInfoImg":{
  position :"relative",
  width: "100px", 
  height: "100px", 
  borderRadius: "8px"
}, 
"& .channelInfoHeader":{
 display : "flex",
 flexDirection : "column",
 justifyContent : "center"
}, 
"& .channelInfoHost":{
 display : "flex",
 alignItems : "center",
 gap : "30px"
}, 

"& .follow-btn":{
  fontWeight : "bold",
  position : "absolute",
  right : "10px",
  top : "12px"
}, 
"& .modalBox":{
  display : "flex",
  justifyContent : "space-between",
  alignItems:"center",
  gap : "30px",
  position :"relative",
},

});
const webStyles = {
  title : {
    fontWeight: 900,
    letter: "-0.5%",
    color: "#fff",
    fontFamily: "Nexa-Heavy"
  },
  titledesc : {
    fontWeight: 400,
    color: "#fff",
    fontFamily: "Inter"
  },
  container: {
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const
}

const ShareChannelModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height : 466,
  bgcolor: '#44403C',
  borderRadius:2,
  p: 4,
  boxSizing:"border-box"
};

const SharePostModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 999,
  height : 343,
  bgcolor: '#44403C',
  borderRadius: "8px 8px 32px 8px",
  p: 4, 
  boxSizing:"border-box"
};


const DropDown = styled(Box)(
  {
    display : "flex",
    flexDirection : "column",
    backgroundColor : "#44403C",
    width: "185px",
    border : "1px solid white",
    position: "absolute",
    right: "94px",
  }
)

const DropDownItems = styled(Box)(
  {
    display : "flex",
    alignItems : "center",
    fontFamily :"Nexa-Regular",
    gap : "8px",
    borderBottom : "1px solid white",
    backgroundColor : "#44403C",
    padding : "10px",
    color: "#FFF"
  }
)



const ModalBoxInput = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px", marginLeft: "30px",
    width: "300px"
  })

const CloseIconModal = styled(CloseIcon)({ color: "#fff", position: "absolute", left: "1120px" })

const CheckBoxModal = styled(Checkbox)(
  { color: "#44403c", '&.Mui-checked': { color: "white", }, borderColor: "#fff", width: "40px", height: "40px", border: "1px solid #fff", borderRadius: "10px" }
)
const ShareChannelModal = styled(Box)({
  display: "flex",
  alignItems: "center"
})

const CopyButton = styled(Button)(
  {
    width: "136px",
    height:"56px",
    marginLeft: "15px",
    borderRadius: "8px", color:"#FFF",
    fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
    background:"#FF0807", textTransform:"none",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  }
)

const ShareIcon = styled(IconButton)({
  color: '#fff',
  marginBottom: "11px"
})

const UrlBox = styled(Box)(
  {
    display: "flex",
    width: "100%",
    alignItems:"center"
  }
)


const ContentCopy = styled(ContentCopyIcon)(
  {
    color: "#fff",
    marginRight: "12px"
  }
)

const ChannelTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "61%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
)

const EmbededTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "61%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
)
const ShareChannelText = styled(Typography)({
  fontFamily: "Nexa-Heavy",
  color: "#fff"
})

const ZeroInput = styled(Typography)({
  backgroundColor: "#000",
  border: "2px solid #fff",
  borderRadius: "5px",
  width: "46px",
  height: "46px",
  padding: "10px",
  color: "#fff",
  display: "flex",
  justifyContent: "start",
  alignItems: "center"
})

const LinkContainer = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center"
})

const CopyButton2 = styled(Button)({
  width: "136px",
  height:"56px",
  marginLeft: "15px",
  borderRadius: "8px", color:"#FFF",
  fontSize:"16px", fontWeight:900, fontFamily:"Nexa-Heavy",
  background:"#FF0807", textTransform:"none",
  "&:hover": {
    backgroundColor: "#FF0807"
  }
}
)

const PodcastContentBox = styled(CardContent)({
  padding: '22px 33px 22px 43px',
})

const AvatarImage = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  marginRight: '10px',
});

const TextFieldContainer = styled(Box)({
  backgroundColor: 'black',
  borderRadius: '20px 20px 0px 0px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledTextField = styled(TextField)({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    borderRadius: '8px',
    padding: '10px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
})

const ReplyButtonBox = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
});

const StyledIconButton = styled(IconButton)({
  color: 'white',
  backgroundColor: '#DADADA',
});

const TextComment = styled(Typography)({
  fontSize: "30px", 
  marginTop: "25px", 
  lineHeight: "40px",
  color: "#FFFFFF"
})

const StyledMenu = styled(Menu)({
  '.MuiList-root': {
    padding: 0,
  },
  '.MuiPaper-root': {
    borderRadius: 0,
  },
})

const StyledMenuItem = styled(MenuItem)({
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  fontSize: "14px",
  width: "233px",
  display: "flex",
  alignItems: "center",
  '& img': {
    marginRight: "8px",
  },
});

const PodcastInnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const PodcastContent = styled(CardContent)({
  padding: '16px',
});

const TextareaWrapper = styled(Box)({
  backgroundColor: '#030303',
  borderRadius: '0px 0px 7px 7px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledTextFieldSecond = styled(TextField)({
  height: '90px',
  backgroundColor: '#030303',
  color: 'white',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    padding: '15px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  input: {
    fontFamily: 'Nexa-Heavy',
    fontWeight: 100,
    fontSize: '14px',
    color: '#FFFFFF',
  },
});

const FilePreviewBox = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  backgroundColor: 'black',
  borderRadius: '8px',
  padding: '10px',
  position: 'relative',
});

const FileImage = styled('img')({
  width: '238px',
  height: '170px',
  borderRadius: '8px',
});

const FileRemoveIconButton = styled(IconButton)({
  position: 'absolute',
  left: '210px',
  top: '12px',
});

const ActionButtonsBox = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
});

const FileUploadButton = styled(IconButton)({
  height: '27px',
  width: '27px',
  padding: '10px',
  '&:hover': {
    backgroundColor: '#1976D2',
  },
});

const SendButton = styled(IconButton)({
  height: '27px',
  width: '27px',
  padding: '10px',
  '&:disabled': {
    backgroundColor: '#DADADA',
  },
});

const menuStyle = {
  "& .MuiPaper-root": {
      backgroundColor: "#44403C",
      color: "#FFFFFF",
      border: "1px solid #ffffff",
      width: "233px",
  },
  "& .MuiList-root": {
    padding:"0px"
  },
}
// Customizable Area End
