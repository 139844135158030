import React from 'react'; 
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import BuildController, { Props }  from "./BuildController.web";
import {
    Box,
    styled,
    Button,
    Card,
    Typography,
    Dialog
} from "@mui/material";
import {  mediaCardImg } from "./assets";
import ShareIcon from '@mui/icons-material/Share';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SwitchButton from "./components/SwitchButton.web";
import TvIcon from '@mui/icons-material/Tv';
import MediaManagementCard from "./components/MediaManagementCard.web";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

const MIN_WINDOW_HEIGHT = 900

export default class MediaManagement extends BuildController {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
      
      return (
        <Box ref={this.scrollRef} sx={{ maxHeight: "100%", overflowY: "auto" }}>
        <StyledWrapper>
        <Typography fontFamily={"Nexa-Heavy"} paddingBottom={2} fontSize={"30px"} color={"white"}>
            Media Management
        </Typography>
        
        <Box>
            <Box
            height="80px"
            bgcolor="red"
            sx={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
            }}
            >
            </Box>

            <Box bgcolor={"#44403C"} color={"white"} padding={"24px 48px 40px 48px"}>
            <Card sx={{ padding: "16px", display: "flex", justifyContent: "space-between", backgroundColor: "#44403C",  marginBottom:"12px", boxShadow: "0px 22px 70px 4px" }}>
                <Box display={"flex"} gap={2}>
                    <img
                    src={this.state.podcastData.cover_image || mediaCardImg}
                    alt="sponsored Img"
                    style={{ objectFit: "cover",height:"210px", width: "230px", borderRadius:"8px" }}
                    />

                <Box color={"white"} style={{display:"flex", flexDirection:"column", gap:"8px"}}>
                    <Typography style={{fontWeight:900, width:"350px", textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} fontFamily={"Nexa-Heavy"} fontSize={"40px"}>{this.state.podcastData.name}</Typography>
                    <Typography style={{fontWeight:900, width:"350px", textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} fontFamily={"Nexa-Heavy"} fontSize={"16px"}>{this.state.podcastData.subtitle}</Typography>
                    <Box display={"flex"} gap={2} mb={1}>
                        <Button
                            variant="outlined"
                            startIcon={<ShareIcon />}
                            sx={{
                                ...buttonStyles,
                                backgroundColor: "#312F2D",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "#312F2D",
                                    color: "white", 
                                    border: "none",
                                },
                            }}
                        >
                            Share
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<TrendingUpIcon />}
                            sx={{
                                ...buttonStyles,
                                background: "black", 
                                color: "white", 
                                "&:hover": {
                                    background: "black", 
                                    color: "white", 
                                    cursor: "pointer",
                                    border:"none"
                                },
                            }}
                        >
                            Analytics
                        </Button>
                    </Box>

                    <Button
                        variant="contained"
                        size="medium"
                        sx={{
                            ...buttonStyles,
                            background: "white", 
                            color: "black", 
                            "&:hover": {
                                background: "white", 
                                color: "black", 
                            },
                        }}
                    >
                        RSS Feed
                    </Button>

                </Box>
                </Box>

                <Box display={"flex"} marginTop={"1rem"} flexDirection={"column"} gap={3}>
                <Button
                    variant="outlined"
                    size="medium"
                    sx={{
                        textTransform:"capitalize",
                        padding: "8px 40px",
                        color: "white",
                        backgroundColor: "red",
                        fontFamily: "Nexa-Heavy",
                        fontSize: "0.875rem",
                        border: "solid red",
                        borderRadius:"8px",
                        "&:hover": {
                        backgroundColor: "red", 
                        color: "white", 
                        border: "solid red", 
                        },
                    }}
                    onClick={this.handleUploadNewContent}
                    >
                    Upload New Content
                </Button>

                
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                    <Box display={"flex"} alignItems={"center"} color={"white"} gap={1}>
                    <OndemandVideoIcon />
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"1rem"}>View All Episodes</Typography>
                    </Box>
                    <SwitchButton checked={true} onChange={() => {}} />
                </Box>

                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                    <Box display={"flex"} alignItems={"center"} color={"white"} gap={1}>
                    <TvIcon />
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"1rem"}>View Premium Episodes</Typography>
                    </Box>
                    <SwitchButton checked={true} onChange={() => {}} />
                </Box>
                </Box>
            </Card>
            {this.state.podcastEpisodes.map((card:any, index:number) => (
                 <MediaManagementCard
                    key={index}
                    sponsoredImage={this.resolveImageCondition(card)}
                    title={card.attributes.name}
                    subTitle={card.attributes.description}
                    episode={`Episode ${card.attributes.episode_number}`}
                    publishDate={`${card.attributes?.publish_date?.publish_date}`}
                    status={card.attributes.status}
                    isPremium={card.attributes.premium}
                    episodeType = {card.attributes.episode_type}
                    onShare={() => {}}
                    onEdit={() => {}}
                    onAnalytics={() => {}}
                    onDelete={() => {this.handleDeleteClick(card.id)}}
                />
            ))}
            <Dialog
                slotProps={{
                    backdrop: {
                        sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }}
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: "600px",
                        width: "600px",
                        background: "#44403C",
                        borderRadius: '8px',
                    },
                }}
                open={this.state.isDeleteEpisode}
                onClose={this.onCancelHandler}
            >
                <Box onClick={this.onCancelHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                    <CloseIcon sx={{ color: "white", cursor: "pointer" }} />
                </Box>
                <Box sx={{ padding: "0px 40px" }}>
                    <Typography sx={{ fontSize: "24px", fontFamily: "Nexa-Heavy", fontWeight:900, color:"#FFFFFF", marginTop: "12px" }}>
                        Are You Sure You Delete this episode?
                    </Typography>
                </Box>
                <Divider sx={{ marginTop: "24px", borderColor: 'white' }} />
                <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"flex-end"}>
                    <Button onClick={this.onCancelHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height: "56px",
                        width: '120px',
                        "&:hover": {
                            background: "black",
                        }
                    }}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.deleteEpisode}
                        sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "white",
                            background: "#FF0807",
                            height: "56px",
                            width: '120px',
                            "&:hover": {
                                background: "#FF0807",
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "white",
                            },
                        }}
                        variant="contained"
                    >
                        Accept
                    </Button>
                </Box>
            </Dialog>
            { this.state.loading && (
            <Box sx={{ display: 'flex', justifyContent:"center" , height:"408px", alignItems:"center"}}>
                <CircularProgress sx={{ color: 'red' }}/>
            </Box>
            ) }
            </Box>
        </Box>
        </StyledWrapper>

        <Footer navigation={this.props.navigation} id={"1"} />    
        </Box>
      );
    }
}

const StyledWrapper = styled("div")({
    background: "black",
    padding:"20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
})

     

    const buttonStyles = {
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        fontSize: "0.875rem",
        textTransform: "capitalize",
        width: "150px", 
        padding: "8px 0", 
        border: "none",
        boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", 
        "&:hover": {
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
        },
    };